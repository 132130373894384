export default {
	background: {
		padding: '78px',
		background: `url('/images/yellow-backdrop.jpg')`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: '40% 85%',
		opacity: '0.96'
	}
};
